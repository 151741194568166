var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("create-or-update-employee-dialog", {
        attrs: { dialog: _vm.createOrUpdateDialog, editedItem: _vm.editedItem },
        on: {
          close: function ($event) {
            return _vm.closeCreateOrUpdateDialog()
          },
        },
      }),
      _c(
        "v-layout",
        { attrs: { "align-center": "", row: "", "fill-height": "" } },
        [
          _c("v-flex", { attrs: { xs4: "" } }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.authorizedEmployees.length) + " "),
            ]),
            _vm._v(" vinculado(s),\n      "),
            _c("strong", [
              _vm._v(_vm._s(_vm.unauthorizedEmployees.length) + " "),
            ]),
            _vm._v(" pendente(s).\n    "),
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "green darken-1 white--text" },
              on: {
                click: function ($event) {
                  return _vm.newEmployee()
                },
              },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-account")]),
              _vm._v("Novo Colaborador\n    "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _vm.unauthorizedEmployees.length > 0
            ? _c("v-flex", { attrs: { xs12: "" } }, [
                _vm._v(
                  "\n      Pendentes (" +
                    _vm._s(_vm.unauthorizedEmployees.length) +
                    ")\n    "
                ),
              ])
            : _c(
                "v-flex",
                { staticClass: "mt-3 caption", attrs: { xs12: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "pa-2 elevation-1 text-xs-center" },
                    [_vm._v("Não há usuários pendentes")]
                  ),
                ]
              ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            _vm._l(_vm.unauthorizedEmployees, function (employee) {
              return _c("employee-card", {
                key: employee.id,
                attrs: { employee: employee, status: "unauthorized" },
              })
            }),
            1
          ),
          _vm.authorizedEmployees.length > 0
            ? _c(
                "v-flex",
                { staticClass: "mt-3 caption", attrs: { xs12: "" } },
                [
                  _vm._v(
                    "Vinculados (" +
                      _vm._s(_vm.authorizedEmployees.length) +
                      ")"
                  ),
                ]
              )
            : _c(
                "v-flex",
                { staticClass: "mt-3 caption", attrs: { xs12: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "pa-2 elevation-1 text-xs-center" },
                    [_vm._v("Não há usuários aprovados")]
                  ),
                ]
              ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            _vm._l(_vm.authorizedEmployees, function (employee) {
              return _c("employee-card", {
                key: employee.id,
                attrs: { employee: employee, status: "authorized" },
                on: {
                  edit: function ($event) {
                    return _vm.editEmployee($event)
                  },
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }