var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mt-3" },
    [
      _c(
        "v-card-title",
        {},
        [
          _c("v-icon", [_vm._v("mdi-account")]),
          _c("span", [
            _vm._v(
              _vm._s(_vm.employee.user.name) +
                " (" +
                _vm._s(_vm.employee.user.email) +
                ")"
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "grey lighten-3" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", "align-center": "" } },
            [
              _c("span", [
                _vm._v("CPF: "),
                _c("strong", [
                  _vm._v(_vm._s(_vm.formatCpfCnpj(_vm.employee.user.cpf))),
                ]),
              ]),
              _c("span", { staticClass: "ml-1" }, [
                _vm._v("Permissão: "),
                _c("strong", [
                  _vm._v(" " + _vm._s(_vm.translateRoles(_vm.employee.roles))),
                ]),
              ]),
              _c("v-spacer"),
              _vm.status === "authorized" && _vm.employeeIsApproved
                ? _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", left: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g({ attrs: { icon: "" } }, on),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-dots-horizontal"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2955327160
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-tile",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.edit()
                                },
                              },
                            },
                            [_c("v-list-tile-title", [_vm._v("Editar")])],
                            1
                          ),
                          _c("v-divider"),
                          _vm.currentUser.roles.includes("company") ||
                          _vm.currentUser.roles.includes("admin") ||
                          _vm.currentUser.user.id === _vm.employee.user.id
                            ? _c(
                                "v-list-tile",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.unlinkEmployee()
                                    },
                                  },
                                },
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(
                                      "\n              Excluir\n            "
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.employeeIsApproved
                ? _c(
                    "span",
                    { staticClass: "unauthorized-employee-actions" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "elevation-1",
                          attrs: { small: "", flat: "", color: "red" },
                          on: { click: _vm.unlinkEmployee },
                        },
                        [
                          _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v(
                              "\n            mdi-close-circle-outline\n          "
                            ),
                          ]),
                          _vm._v("\n          Não Vincular\n        "),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "elevation-1",
                          attrs: { small: "", flat: "", color: "blue" },
                          on: {
                            click: function ($event) {
                              return _vm.approveLink()
                            },
                          },
                        },
                        [
                          _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v("\n            mdi-check\n          "),
                          ]),
                          _vm._v("\n          Aprovar Vínculo\n        "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }