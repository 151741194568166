<template>
  <v-container>
    <create-or-update-employee-dialog
      :dialog="createOrUpdateDialog"
      :editedItem="editedItem"
      @close="closeCreateOrUpdateDialog()"
    />
    <v-layout align-center row fill-height>
      <v-flex xs4>
        <strong>{{authorizedEmployees.length}} </strong> vinculado(s),
        <strong>{{unauthorizedEmployees.length}} </strong> pendente(s).
      </v-flex>
      <v-spacer/>
      <v-btn color="green darken-1 white--text" @click="newEmployee()">
        <v-icon left>mdi-account</v-icon>Novo Colaborador
      </v-btn>
    </v-layout>
    <v-layout column>
      <v-flex xs12
              v-if="unauthorizedEmployees.length > 0">
        Pendentes ({{unauthorizedEmployees.length}})
      </v-flex>
      <v-flex xs12 v-else
              class="mt-3 caption">
        <div class="pa-2 elevation-1 text-xs-center">Não há usuários pendentes</div>
      </v-flex>
      <v-flex xs12>
        <employee-card
          v-for="employee in unauthorizedEmployees"
          :key="employee.id"
          :employee="employee"
          status="unauthorized"
        />
      </v-flex>
      <v-flex xs12
              v-if="authorizedEmployees.length > 0"
              class="mt-3 caption">Vinculados ({{authorizedEmployees.length}})</v-flex>
      <v-flex xs12 v-else
              class="mt-3 caption">
        <div class="pa-2 elevation-1 text-xs-center">Não há usuários aprovados</div>
      </v-flex>

      <v-flex xs12>
        <employee-card
          v-for="employee in authorizedEmployees"
          :key="employee.id"
          :employee="employee"
          @edit="editEmployee($event)"
          status="authorized"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CreateOrUpdateEmployeeDialog from '@/components/person/employees/CreateOrUpdateEmployeeDialog.vue';
import EmployeeCard from '@/components/person/employees/EmployeeCard.vue';

export default {
  name: 'PersonEmployees',
  components: { CreateOrUpdateEmployeeDialog, EmployeeCard },
  data() {
    return {
      createOrUpdateDialog: false,
      editedItem: {},
    };
  },
  methods: {
    newEmployee() {
      this.editedItem = null;
      this.createOrUpdateDialog = true;
    },
    editEmployee(employee) {
      this.editedItem = employee;
      this.createOrUpdateDialog = true;
    },
    closeCreateOrUpdateDialog() {
      this.createOrUpdateDialog = false;
      this.editedItem = null;
    },
  },
  computed: {
    person() {
      return this.$store.getters['person/person'];
    },
    employees() {
      return this.$store.getters['person/employees'];
    },
    authorizedEmployees() {
      return this.employees.filter(e => e.approved);
    },
    unauthorizedEmployees() {
      return this.employees.filter(e => !e.approved);
    },

  },
};

</script>
