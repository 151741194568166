<template>
  <v-card class="mt-3">
    <v-card-title class="">
      <v-icon>mdi-account</v-icon>
      <span>{{employee.user.name}} ({{employee.user.email}})</span>
    </v-card-title>

    <v-card-text class="grey lighten-3">
      <v-layout row align-center>
        <span>CPF: <strong>{{formatCpfCnpj(employee.user.cpf)}}</strong></span>
        <span class="ml-1">Permissão: <strong> {{translateRoles(employee.roles)}}</strong> </span>
        <v-spacer />
        <v-menu
          v-if="status === 'authorized' && employeeIsApproved"
          bottom
          left>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-tile
              @click="edit()">
              <v-list-tile-title>Editar</v-list-tile-title>
            </v-list-tile>
            <v-divider></v-divider>
            <v-list-tile v-if="currentUser.roles.includes('company') || currentUser.roles.includes('admin') || (currentUser.user.id === employee.user.id)"
              @click="unlinkEmployee()">
              <v-list-tile-title>
                Excluir
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
        <span
          v-else-if="employeeIsApproved"
          class="unauthorized-employee-actions"
        >
          <v-btn
            class="elevation-1"
            small
            flat
            color="red"
            @click="unlinkEmployee"
          >
            <v-icon class="mr-1">
              mdi-close-circle-outline
            </v-icon>
            Não Vincular
          </v-btn>
          <v-btn
            class="elevation-1"
            small
            flat
            @click="approveLink()"
            color="blue"
          >
            <v-icon class="mr-1">
              mdi-check
            </v-icon>
            Aprovar Vínculo
          </v-btn>
        </span>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import formatCpfCnpj from '@/utils/formatCpfCnpj';
import employeeService from '@/services/employee-service';
import { mapGetters } from 'vuex';

export default {
  mounted() {
    console.log(this.currentUser);
  },
  name: 'EmployeeCard',
  props: ['employee', 'status'],
  data() {
    return {
      formatCpfCnpj,
      isFetchingOffices: true,
      roles: {
        admin: 'Administrador',
        company: 'Empresa',
        technical: 'Responsável Técnico',
        receiver: 'Responsável pelo Recebimento',
      },
    };
  },
  methods: {
    approveLink() {
      employeeService
        .approveLink(this.employee.user.id, this.person.id)
        .then(() => {
          this.$store.dispatch('person/fetchEmployees', this.person.id);
          this.showSnackbar('Funcionário vinculado com sucesso', 'success');
        })
        .catch(() => {
          this.showSnackbar('Erro ao vincular funcionário', 'error');
        });
    },
    unlinkEmployee() {
      employeeService
        .unlinkEmployee(this.employee.user.id, this.person.id)
        .then(() => {
          this.$store.dispatch('person/fetchEmployees', this.person.id);
          this.showSnackbar('Funcionário desvinculado com sucesso', 'success');
        })
        .catch(({ response }) => {
          this.showSnackbar(response.data[0], 'error');
        });
    },
    showSnackbar(message, status) {
      this.$store.dispatch('application/toggleSnackbar', {
        show: true,
        timeout: 6000,
        color: status,
        message,
        messageIcon: 'mdi-alert-outline',
      });
    },
    translateRoles(userRoles) {
      const vm = this;
      return userRoles.map(role => vm.roles[role]).join(', ');
    },
    edit() {
      this.$emit('edit', this.employee);
    },
  },
  computed: {
    ...mapGetters({
      person: 'person/person',
      currentUser: 'auth/currentUser',
      employees: 'person/employees',
    }),
    employeeIsApproved() {
      return this.employees.filter(employee => employee.approved && employee.user.id === this.currentUser.user.id).length;
    },
  },
};
</script>
<style scoped lang="scss">
  .v-card__text{
    padding: 0 16px !important;
  }

  .unauthorized-employee-actions{
    button {
      background-color: #fff !important;
    }
  }
</style>
